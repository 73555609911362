<template>
    <v-sheet class="p-0">
        <!-- overlay refresh หน้า page      -->
        <v-overlay :value="isShowOverlay"
                   opacity="0.7"
                   z-index="999">
            <div class="center">
                <v-row class="fill-height px-4 py-3"
                       align-content="center"
                       justify="center">
                    <v-col cols="12">
                        <v-progress-circular indeterminate
                                             size="64"></v-progress-circular>
                        <!--                        <v-progress-linear color="primary accent-4"-->
                        <!--                                           indeterminate-->
                        <!--                                           rounded-->
                        <!--                                           height="6"></v-progress-linear>-->
                    </v-col>
                    <v-col class="text-subtitle-1 text-center"
                           cols="12">
                        {{ $t('กรุณารอสักครู่ กำลังดำเนินการ') }}...
                    </v-col>
                </v-row>
            </div>
        </v-overlay>
        <v-overlay :value="isShowOverlayPayment"
                   opacity="0.7"
                   z-index="999">
            <div class="center">
                <v-row class="fill-height px-4 py-3"
                       align-content="center"
                       justify="center">
                    <v-col cols="12">
                        <v-progress-circular indeterminate
                                             size="64"></v-progress-circular>
                    </v-col>
                    <v-col class="text-subtitle-1 text-center"
                           cols="12">
                        <template v-if="orderData && orderData.is_cod">
                            {{ $t('กำลังดำเนินการยืนยันการสั่งซื้อ') }}...
                        </template>
                        <template v-else-if="paymentInputState && paymentInputState.selectedMethod === 'bank_transfer'">
                            {{ $t('กรุณารอสักครู่ กำลังดำเนินการ') }}
                        </template>
                        <template v-else>
                            {{ $t('กำลังดำเนินการไปยังการชำระเงิน') }}...
                        </template>
                    </v-col>
                </v-row>
            </div>
        </v-overlay>
        <template v-if="false && orderData">
            <pre>{{ orderData.lang_key }}</pre>
            <pre>{{ orderData.currency_key }}</pre>
            <pre>{{ stepMapping }}</pre>
            <pre>{{ stepMetas }}</pre>
            <pre>isShowOrderDetailOnTop : {{ isShowOrderDetailOnTop }}</pre>
            <pre>isOrderFixedTop : {{ orderFixedTop }}</pre>
            <pre>always_show_product : {{ shopData.setting.always_show_product }}</pre>
            <pre>expandOrderDetail : {{ expandOrderDetail }}</pre>
        </template>
        <!-- เส้น loading ajax -->
        <div class="fixed-top">
            <v-progress-linear v-if="isAjaxLoading || isAjaxResponse"
                               class="fixed-top"
                               :indeterminate="isAjaxLoading"
                               value="100"
                               :color="(isAjaxLoading)?'primary':'success'"></v-progress-linear>
        </div>
        <v-snackbar v-if="orderData?.debug"
                    :value="true"
                    :top="isDesktop"
                    :timeout="1000000"
                    min-width="150"
                    max-width="100%"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="error"
                    text
                    :rounded="isDesktop?'':'pill'"
                    outlined>
            <pre>{{ orderData.debug }}</pre>
        </v-snackbar>
        <!-- snackbar สำหรับแสดงว่า Load เรียบร้อย -->
        <v-snackbar v-if="!isAjaxResponseMessage && isAjaxResponse"
                    v-model="isAjaxResponse"
                    timeout="3000"
                    :top="isDesktop"
                    min-width="150"
                    max-width="100%"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="success"
                    text
                    :rounded="isDesktop?'':'pill'"
                    outlined
                    :style="(isShowOrderStatusTopBanner)?'padding: 40px 15px 15px;':'padding: 20px 15px 15px;' + 'opacity: 0;'">
            {{ $t('อัพเดทแล้ว') }}
            <template v-slot:action="{ attrs }">
                <b style="cursor: pointer"
                   class="mr-2 ml-0"
                   color="primary"
                   @click="isAjaxResponse = false">OK</b>
            </template>
        </v-snackbar>
        <!-- snackbar สำหรับแสดงข้อความ RESPONSE -->
        <v-snackbar v-else-if="isAjaxResponseMessage != ''"
                    v-model="isAjaxResponse"
                    timeout="20000"
                    :top="isDesktop"
                    min-width="150"
                    max-width="360"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="error"
                    :rounded="(isDesktop || isAjaxResponseMessage.length > 30)?'':'pill'"
                    :style="(isShowOrderStatusTopBanner)?'padding: 40px 15px 15px;':'padding: 20px 15px 15px;'">
            <span v-html="computedResponseMessage"></span>
            <template v-slot:action="{ attrs }">
                <b style="cursor: pointer"
                   class="mr-2 ml-0"
                   color="primary"
                   @click="isAjaxResponse = false; isAjaxResponseMessage = ''">{{ $t('ปิด') }}</b>
            </template>
        </v-snackbar>
        <!-- snackbar สำหรับแสดงข้อความ Copy เสร็จ -->
        <v-snackbar v-model="isCopied"
                    timeout="4000"
                    :top="isDesktop"
                    min-width="150"
                    max-width="100%"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="success"
                    text
                    outlined
                    :style="(isShowOrderStatusTopBanner)?'padding: 40px 15px 15px;':'padding: 20px 15px 15px;'">
            {{ $t('คัดลอกแล้ว') }}
            <template v-slot:action="{ attrs }">
                <b style="cursor: pointer"
                   class="mr-2 ml-0"
                   color="primary"
                   @click="isCopied = false">OK</b>
            </template>
        </v-snackbar>
        <!-- ปุ่ม Contact ที่ขวาล่าง -->
        <ContactButton></ContactButton>
        <!-- Right Pane ที่เป็นข้อมูลสมาชิก -->
        <the-user-drawer v-model="drawer"></the-user-drawer>
        <template v-if="!orderData && !shopData && !isAjaxLoading">
            <page-not-found></page-not-found>
        </template>
        <template v-else-if="(!orderData || status == 'wait_order') && existingOrderOwner">
            <!-- order มีเจ้าของแล้ว และไม่ใช่เรา : แสดงหน้า login -->
            <!--เพิ่มเรื่องว่าถ้าเข้ามาแบบมี order แล้ว จะสามารถข้าม login require แล้วแสดงข้อมูลแบบ *** ได้ -->{{ loader }}
            <the-login-required></the-login-required>
        </template>
        <!-- ข้อมูล orderData พร้อมแล้ว : ส่วนแสดงผลหลัก -->
        <template v-else-if="orderData">
            <!-- ถ้า Order เคยผูกบัญชีแล้ว จะมี Component นี้เพื่อรอดักกรณีมีการกดคลิกปุ่มที่จะแก้ไขต่างๆ ให้ Popup Component นี้ขึ้นมา -->
            <component :is="isDesktop?'VDialog':'VBottomSheet'"
                       v-model="isLoginRequired"
                       :max-width="isDesktop?'600px':'100%'"
                       :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'">
                <v-card>
                    <the-login-required></the-login-required>
                    <v-card-actions class="flex_center pb-6">
                        <v-btn text
                               x-large
                               color="primary"
                               class="px-4"
                               @click="isLoginRequired = false">
                            {{ $t('ปิดหน้าต่าง') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </component>
            <!-- กรณีเมื่อจ่ายเงิน LnwPay แล้วกลับมาที่ Lnw.me จะมี Component นี้ให้ Popup Component นี้ขึ้นมา -->
            <v-dialog v-model="isShowLnwPayDialog"
                      persistent
                      :overlay-opacity="0.95"
                      style="z-index: 999;"
                      :max-width="isDesktop?'600px':'100%'"
                      transition="dialog-transition">
                <v-card>
                    <lnw-pay-call-back></lnw-pay-call-back>
                    <v-card-actions class="flex_center pb-6">
                        <v-btn text
                               x-large
                               color="primary"
                               :disabled="isWaitingForChangeStatus"
                               @click="forceHideLnwPayDialog = true">
                            {{ isWaitingForChangeStatus ? $t('กรุณารอสักครู่') : $t('ปิดหน้าต่าง') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- แถบเหนือกล่อง ที่มีปุ่ม Quick Action -->
            <v-scroll-y-transition>
                <div @click="showAdminButton = !showAdminButton"
                     class="sticky-top">
                    <v-banner v-if="isShowOrderStatusTopBanner"
                              single-line
                              id="bannerStatus"
                              :color="(isShopClose)?'red':orderStatusMeta.color"
                              class="white--text small">
                        <v-btn v-if="showAdminButton"
                               target="_blank"
                               :href="shopData.url + 'manage/order/info/' + orderData.order_id"
                               plain
                               class="position-absolute"
                               style="top: 4px; right: 5px; z-index: 2"
                               color="white"
                               x-small>Admin
                        </v-btn>
                        <div :class="(orderStatusMeta.last && !isShopClose)?'':'banner_loop'">
                            <template v-if="!isShopClose">
                                <div class="text-center">
                                    <span v-html="orderStatusMeta.banner"></span>
                                    <span v-if="(status === 'wait_order' || status === 'wait_payment')">
                                <template v-if="orderData && orderData.expired_time">
                                    <countdown :end-time="parseDateToCounter(orderData.expired_time)"><span slot="process"
                                                                                                            slot-scope="anyYouWantedScopName">
                                        {{ $t('ภายใน') }}
                                        <span v-if="anyYouWantedScopName.timeObj.d != 0">
                                            {{ anyYouWantedScopName.timeObj.d }} {{ $t('วัน') }}
                                        </span>
                                        {{ anyYouWantedScopName.timeObj.h }}:{{ anyYouWantedScopName.timeObj.m }}:{{ anyYouWantedScopName.timeObj.s }}
                                        </span><span slot="finish"> {{ $t('หมดอายุแล้ว') }}</span>
                                    </countdown>
                                </template>
                                <template v-else-if="!isWaitingShippingPrice">
                                    {{ $t('ก่อนบิลหมดอายุ') }}
                                </template>
                            </span>
                                </div>
                            </template><!-- ร้านไม่ปิด -->
                            <template v-else><!-- ร้านปิด -->
                                <div class="text-center">
                                    <span>{{ $t('ร้านค้าปิดร้านชั่วคราว') }}</span>
                                </div>
                            </template>
                        </div>
                    </v-banner>
                </div>
            </v-scroll-y-transition>
            <div :class="{ placedOrder : status !== 'wait_order', withBanner : isShowOrderStatusTopBanner}">
                <div id="order-wrapper"
                     :class="showCart?'cart-wrapper':''">
                    <div class="space-left"></div>
                    <div class="space-right"></div>
                    <div class="header"
                         ref="header">
                        <div v-if="isRealMall"
                             class="logo">
                            <a :href="mall.url"
                               target="_blank"> <img alt=""
                                                     :src="require('@/assets/mall/'+mall.key+'.png')"/>
                                <span>{{ mall.name }}</span> </a>
                            <v-icon class="mx-3">mdi-chevron-right</v-icon>
                            <span v-if="shopData"
                                  class="logo">
                                <img alt=""
                                     :src="shopData.avatar"/>
                                <span>{{ shopData.name }}</span>
                            </span>
                        </div>
                        <!--                        shop -->
                        <div v-else
                             class="logo">
                            <a v-if="shopData"
                               :href="shopData.url"
                               target="_blank"> <img alt=""
                                                     :src="shopData.avatar"/> <span>{{ shopData.name }}</span> </a>
                        </div>
                        <div class="user">
                            <div v-if="userData"
                                 class="avatar"
                                 @click.stop="drawer = !drawer">
                                <v-img aspect-ratio="1"
                                       alt=""
                                       :src="userData.avatar"/>
                            </div>
                            <!--                            <div v-else class="avatar flex_center">-->
                            <!--                                <v-icon @click="isLoginRequired = true">mdi-login</v-icon>-->
                            <!--                            </div>-->
                            <template v-if="isRealMall || gointer_enabled">
                                <div class="flag-container"
                                     @click="toggleLang()">
                                    <div class="flag"
                                         :class="$i18n.locale + '-flag'"></div>
                                    <span v-if="isDesktop"
                                          class="text-uppercase">{{ $i18n.locale }}</span>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!-- ส่วนแสดงผล Cart เพื่อจัดการเกี่ยวกับสินค้า / โปรโมชั่น / ของแถม -->
                    <template v-if="showCart">
                        <div class="step expanded"
                             style="min-height: 0">
                            <div class="cart-container">
                                <the-order-detail>
                                    <v-alert color="#f5f5f7"
                                             :class="isDesktop?'px-8 py-6 rounded-xl mt-4 mb-6':'px-6 py-4 rounded-lg mt-4 mb-2'">
                                        <div class="just_flex" :style="isDesktop?'':'justify-content: center; flex-wrap:wrap; gap: 1rem;'">
                                            <div :style="isDesktop?'':'text-align: center'">
                                                <b :style="isDesktop?'font-size: 1.75em':'font-size: 1.25em'"> {{ $t('ราคาสุทธิ') }} ฿{{ cpNetPrice }} </b>
                                                <div class="minor">
                                                    <template v-if="orderData.is_wait_confirm_shipping_price || (status == 'wait_order' && selected_shipping.format == 'custom')">
                                                        {{ $t('คิดค่าส่งภายหลัง') }}
                                                    </template>
                                                    <template v-else-if="!hasShippingPrice">
                                                        {{ $t('ราคานี้ยังไม่รวมค่าจัดส่ง') }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $t('รวมค่าส่งแล้ว') }}
                                                    </template>
                                                </div>
                                            </div>
                                            <v-btn :x-large="isDesktop"
                                                   :large="!isDesktop"
                                                   style="min-width: 240px"
                                                   :block="!isDesktop"
                                                   :disabled="!cpOrderItems.length"
                                                   depressed
                                                   @click="showCart = !showCart"
                                                   color="primary">{{ $t('เริ่มสั่งซื้อ') }}
                                            </v-btn>
                                        </div>
                                    </v-alert>
                                </the-order-detail>
                                <div class="submitArea mt-4">
                                    <v-btn color="primary"
                                           class="backButton"
                                           height="52"
                                           text
                                           :href="(isRealMall)?mall.url:shopData.url">
                                        <v-icon color="primary"
                                                class="mr-2">mdi-chevron-left
                                        </v-icon>
                                        <template v-if="orderData.order_status === 'wait_order' && canEditCart">
                                            {{ isDesktop?$t('เลือกซื้อสินค้าเพิ่ม'):$t('เลือกซื้อเพิ่ม') }}
                                        </template>
                                        <template v-else>
                                            {{ $t('กลับไปร้านค้า') }}
                                        </template>
                                    </v-btn>
                                    <div>
                                        <v-btn x-large
                                               :disabled="!cpOrderItems.length"
                                               block
                                               style="min-width: 240px"
                                               depressed
                                               @click="showCart = !showCart"
                                               color="primary">{{ $t('เริ่มสั่งซื้อ') }}
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <add-more-items item_type="related"></add-more-items>
                            <add-more-items item_type="recent-view"></add-more-items>
                            <faqs></faqs>
                        </div>
                    </template>
                    <template v-else>
                        <!-- ribbon ที่ fixedTop สำหรับ mobile-device ไว้กดมาดูรายละเอียด order ด้านบน -->
                        <the-order-detail-fixed-top v-on:goToHeader="goToHeader"
                                                    v-on:goToOrderInfo="goToOrderInfo"></the-order-detail-fixed-top>
                        <!-- กล่องแสดงรายละเอียด order ด้านบนสุด (หรือขวาบน desktop) -->
                        <the-order-detail v-if="isShowOrderDetailOnTop" class="item-container"></the-order-detail>
                        <!-- ------------------------------- -->
                        <!-- แสดง 3-Steps-หลัก -->
                        <!-- ------------------------------- -->
                        <div class="step"
                             :class="{ fadeInfo : (fadeInfo && !(status == 'wait_order' || status == 'wait_payment'))}">
                            <!-- แถบแสดงชื่อ และโลโก้ ของร้านค้า -->
                            <debug-indicator file-name="src/views/Order.vue"
                                             detail="v-if=status !== 'wait_order'"/>
                            <template v-if="orderStatusMeta
                                            && (status != 'wait_order' || isShopClose)">
                                <div class="default-container status-description elevation-24">
                                    <template v-if="isShopClose">
                                        <v-alert class="mt-4"
                                                 dense
                                                 type="error"
                                                 color="red">
                                            <v-row align="center">
                                                <v-col class="grow">
                                                    {{ $t('ขณะนี้ร้านค้าได้ปิดร้านชั่วคราว หากมีข้อสงสัยต่างๆ กรุณาติดต่อร้านค้าที่ช่องทางต่างๆด้านล่างนี้') }}
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                        <div class="pb-2">{{ $t('ช่องทางติดต่อของร้าน') }} :</div>
                                        <v-tooltip bottom
                                                   v-for="(value , key) in contactList"
                                                   :key="key">
                                            <template v-slot:activator="{ on }">
                                                <v-btn :href="contactMeta[key].url + value"
                                                       target="_blank"
                                                       class="mr-2 mb-2"
                                                       v-on="on"
                                                       fab
                                                       dark
                                                       x-small
                                                       :color="contactMeta[key].color">
                                                    <v-icon small
                                                            v-if="contactMeta[key].icon && key !== 'twitter'">{{ contactMeta[key].icon }}
                                                    </v-icon>
                                                    <img v-if="key === 'line'"
                                                         style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);"
                                                         :src="require('@/assets/social-svg/line.svg')"
                                                         width="16"> <img v-if="key === 'tiktok'"
                                                                          style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);"
                                                                          :src="require('@/assets/social-svg/tiktok.svg')"
                                                                          width="14"> <img v-if="key === 'twitter'"
                                                                                           style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);"
                                                                                           :src="require('@/assets/social-svg/twitter.svg')"
                                                                                           width="14">
                                                </v-btn>
                                            </template>
                                            {{ value }}
                                        </v-tooltip>
                                        <v-divider v-if="status != 'wait_order'"
                                                   class="my-4"></v-divider>
                                    </template>
                                    <!-- snackbar สำหรับให้ Login  -->
                                    <template v-if="existingOrderOwner">
                                        <v-alert class="mt-4 py-4"
                                                 text
                                                 type="info">
                                            <template v-slot:prepend>
                                                <div></div>
                                            </template>
                                            <v-row align="center">
                                                <v-col class="grow py-0">
                                                    {{ $t('เข้าสู่ระบบเพื่อแสดงรายละเอียดบิลสั่งซื้อนี้') }}
                                                    <v-tooltip bottom
                                                               max-width="300">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div v-bind="attrs"
                                                                 v-on="on"
                                                                 class="d-inline-block"
                                                                 style="vertical-align: top">
                                                                <v-icon class="mr-1"
                                                                        color="primary"
                                                                        small>
                                                                    mdi-help-circle-outline
                                                                </v-icon>
                                                            </div>
                                                        </template>
                                                        {{ $t('เนื่องจากบิลนี้ได้มีการเข้าสู่ระบบและผูกบัญชีไว้เรียบร้อยแล้ว ซึ่งจะมีข้อมูลส่วนบุคคลต่างๆ ที่ในขณะนี้คุณเข้ามาในฐานะบุคคลทั่วไป จึงไม่สามารถเข้าดูรายละเอียดได้ ดังนั้นคุณจำเป็นต้องยืนยันตัวตนด้วยการเข้าสู่ระบบอีกครั้ง') }}
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col class="shrink py-0 pr-0">
                                                    <v-btn small
                                                           style="height: 40px;"
                                                           color="primary"
                                                           text
                                                           @click="isLoginRequired = true">
                                                        <v-icon class="p-1">mdi-login</v-icon>
                                                        Login
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </template>
                                    <v-list v-if="status != 'wait_order'">
                                        <v-list-item style="padding-left: 0; padding-right: 0">
                                            <v-list-item-content class="pt-0">
                                                <v-list-item-title>
                                                    <div class="">
                                                        <!--                                                    <img class="mr-1"-->
                                                        <!--                                                         style="width: 12px; height: 12px; vertical-align: middle"-->
                                                        <!--                                                         :src="require('../assets/order_status/' + activeStatus.step + '.png')">-->
                                                        <span class="minor">{{ $t('สถานะบิลที่') }} {{ displayOrderID(orderData.order_id) }}</span>
                                                        <handle-copy-component class="d-inline-block ml-1"
                                                                               ref="handleCopy">
                                                            <v-btn color="primary"
                                                                   text
                                                                   x-small
                                                                   v-clipboard:copy="orderData.order_id">
                                                                <v-icon x-small>mdi-content-copy</v-icon>
                                                            </v-btn>
                                                        </handle-copy-component>
                                                    </div>
                                                    <b>
                                                        <template v-if="!isDesktop">
                                                            {{ $t('สถานะ') }} :
                                                        </template>
                                                        {{ orderStatusMeta.text }} </b>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action v-if="orderStatusMeta.button_text">
                                                <template v-if="orderStatusMeta.status === 'wait_confirm'">
                                                    <confirm-order-dialog>
                                                        <template v-slot:activator="{on,attrs}">
                                                            <v-btn :disabled="!canConfirmReceived"
                                                                   v-bind="(!existingOrderOwner)?attrs:''"
                                                                   v-on="(!existingOrderOwner)?on:''"
                                                                   @click="(existingOrderOwner)?(isLoginRequired = true):''"
                                                                   x-large
                                                                   color="primary"
                                                                   style="vertical-align: top; font-size: 16px;">
                                                                {{ orderStatusMeta.button_text }}
                                                            </v-btn>
                                                        </template>
                                                    </confirm-order-dialog>
                                                </template>
                                                <template v-else-if="orderStatusMeta.button_text ===  $t('สั่งซื้ออีกครั้ง')">
                                                    <v-btn x-large
                                                           @click="orderBuyAgain()"
                                                           :loading="isAjaxLoading"
                                                           :disabled="isAjaxLoading"
                                                           color="primary"
                                                           style="vertical-align: top; font-size: 16px;">
                                                        {{ orderStatusMeta.button_text }}
                                                    </v-btn>
                                                </template>
                                                <v-btn v-else
                                                       x-large
                                                       outlined
                                                       color="primary"
                                                       style="vertical-align: top; font-size: 16px;"
                                                       @click="gotoFirstStep()">
                                                    {{ orderStatusMeta.button_text }}
                                                    <v-icon class="ml-2"
                                                            small>mdi-arrow-down
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <p v-html="orderStatusMeta.desc"></p>
                                    <template v-if="orderData.order_message[orderStatusMeta.status]">
                                        <v-alert class="mt-6 text-sm-body-2" color="primary" icon="mdi-information-outline" text>
                                            {{ orderData.order_message[orderStatusMeta.status] }}
                                        </v-alert>
                                    </template>
                                    <template v-if="canConfirmReceived">
                                        <v-alert color="light-blue lighten-5"
                                                 class="align-center light-blue--text">
                                            <div class="just_flex">
                                                <span class="">{{ $t('หากคุณได้รับสินค้าเรียบร้อยแล้ว กรุณา') }}</span>
                                                <div :class="(isDesktop)?'d-inline-block':'flex-grow-1 d-block mt-4'">
                                                    <confirm-order-dialog>
                                                        <template v-slot:activator="{on,attrs}">
                                                            <v-btn :dense="isDesktop"
                                                                   :text="isDesktop"
                                                                   :outlined="!isDesktop"
                                                                   :large="!isDesktop"
                                                                   block
                                                                   color="light-blue"
                                                                   v-bind="(!existingOrderOwner)?attrs:''"
                                                                   v-on="(!existingOrderOwner)?on:''"
                                                                   @click="(existingOrderOwner)?(isLoginRequired = true):''"
                                                                   style="vertical-align: top; font-size: 16px;">
                                                                <v-icon class="mr-2"
                                                                        small>mdi-checkbox-marked-outline
                                                                </v-icon>
                                                                {{ $t('ยืนยันได้รับสินค้า') }}
                                                            </v-btn>
                                                        </template>
                                                    </confirm-order-dialog>
                                                </div>
                                            </div>
                                        </v-alert>
                                    </template>
                                    <div class="flex_start flex_wrap"
                                         style="gap: 10px 20px;">
                                        <template v-if="orderData.quotation_note_url">
                                            <v-btn :href="(orderData.quotation_note_url != 'permission_denied')?orderData.quotation_note_url:''"
                                                   @click="(orderData.quotation_note_url == 'permission_denied')? isLoginRequired = true: ''"
                                                   text
                                                   target="_blank"
                                                   :large="isDesktop"
                                                   color="primary"
                                                   style="vertical-align: top; font-size: 16px;">
                                                <v-icon color="primary"
                                                        class="mr-2">mdi-download
                                                </v-icon>
                                                {{ $t('ดาวน์โหลดใบเสนอราคา') }}
                                            </v-btn>
                                        </template>
                                        <template v-if="orderData.receipt_url">
                                            <v-btn :href="(orderData.receipt_url != 'permission_denied')?orderData.receipt_url:''"
                                                   @click="(orderData.receipt_url == 'permission_denied')? isLoginRequired = true: ''"
                                                   text
                                                   target="_blank"
                                                   :large="isDesktop"
                                                   color="primary"
                                                   style="vertical-align: top; font-size: 16px;">
                                                <v-icon color="primary"
                                                        class="mr-2">mdi-download
                                                </v-icon>
                                                {{ $t('ดาวน์โหลดใบกำกับภาษี') }}
                                                <template v-if="orderData.is_etax_enabled">
                                                    ( E-tax )
                                                </template>
                                            </v-btn>
                                        </template>
                                        <template v-if="orderData.receipts">
                                            <v-btn v-for="(receipt, idx) in orderData.receipts"
                                                   :href="(receipt != 'permission_denied')?receipt.url:''"
                                                   @click="(receipt == 'permission_denied')? isLoginRequired = true: ''"
                                                   text
                                                   :key="idx"
                                                   target="_blank"
                                                   :large="isDesktop"
                                                   color="primary"
                                                   style="vertical-align: top; font-size: 16px;">
                                                <v-icon color="primary"
                                                        class="mr-2">mdi-download
                                                </v-icon>
                                                {{ $t('ดาวน์โหลดใบกำกับภาษี') }}
                                                <template v-if="orderData.receipts.length > 1">#{{ idx + 1 }}</template>
                                                <template v-if="orderData.is_etax_enabled">
                                                    ( E-tax )
                                                </template>
                                            </v-btn>
                                        </template>
                                        <template v-if="orderData.invoice_note_url">
                                            <v-btn :href="(orderData.invoice_note_url != 'permission_denied')?orderData.invoice_note_url:''"
                                                   @click="(orderData.invoice_note_url == 'permission_denied')? isLoginRequired = true: ''"
                                                   text
                                                   target="_blank"
                                                   :large="isDesktop"
                                                   color="primary"
                                                   style="vertical-align: top; font-size: 16px;">
                                                <v-icon color="primary"
                                                        class="mr-2">mdi-download
                                                </v-icon>
                                                {{ $t('ดาวน์โหลดใบแจ้งหนี้') }}
                                            </v-btn>
                                        </template>
                                        <template v-if="orderStatusMeta.last && orderStatusMeta.button_text !==  $t('สั่งซื้ออีกครั้ง')">
                                            <v-btn @click="orderBuyAgain()"
                                                   text
                                                   :loading="isAjaxLoading"
                                                   :disabled="isAjaxLoading"
                                                   color="primary"
                                                   :large="isDesktop"
                                                   style="vertical-align: top; font-size: 16px;">
                                                <v-icon color="primary"
                                                        class="mr-2">mdi-repeat-variant
                                                </v-icon>
                                                {{ $t('สั่งซื้ออีกครั้ง') }}
                                            </v-btn>
                                        </template>
                                    </div>
                                    <template v-if="orderData.can_set_user">
                                        <v-alert :color="orderData.can_receive_shop_point?'primary':'#999'"
                                                 :text="!orderData.can_receive_shop_point"
                                                 :dark="orderData.can_receive_shop_point"
                                                 class="align-center mt-4">
                                            <div class="just_flex flex-wrap">
                                                <template v-if="orderData.can_receive_shop_point">
                                                    {{ $t('สะสมคะแนนเพื่อรับสิทธิพิเศษจากร้าน') }}
                                                </template>
                                                <template v-else>
                                                    {{ $t('สนใจเป็นสมาชิกเพื่อรับสิทธิพิเศษ') }}
                                                </template>
                                                <div :class="(isDesktop)?'d-inline-block':'flex-grow-1 d-block mt-4'">
                                                    <template v-if="cpFlagIsLogin">
                                                        <v-btn @click="actionClaimOrder"
                                                               :dense="isDesktop"
                                                               :text="isDesktop"
                                                               :outlined="!isDesktop"
                                                               :large="!isDesktop"
                                                               block
                                                               :color="orderData.can_receive_shop_point?'white':'primary'"
                                                               :light="!orderData.can_receive_shop_point"
                                                               style="vertical-align: top; font-size: 16px;">
                                                            <template v-if="orderData.can_receive_shop_point">
                                                                <v-icon class="mr-2">mdi-star-plus
                                                                </v-icon>
                                                                {{ $t('รับคะแนน') }}
                                                            </template>
                                                            <template v-else>
                                                                <v-icon class="mr-2"></v-icon>
                                                                {{ $t('สมัครสมาชิกร้าน') }}
                                                            </template>
                                                        </v-btn>
                                                    </template>
                                                    <template v-else>
                                                        <claim-order-component>
                                                            <template v-slot:activator="{on,attrs}">
                                                                <v-btn :dense="isDesktop"
                                                                       :text="isDesktop"
                                                                       :outlined="!isDesktop"
                                                                       :large="!isDesktop"
                                                                       block
                                                                       :color="orderData.can_receive_shop_point?'white':'primary'"
                                                                       :light="!orderData.can_receive_shop_point"
                                                                       v-bind="attrs"
                                                                       v-on="on"
                                                                       style="vertical-align: top; font-size: 16px;">
                                                                    <template v-if="orderData.can_receive_shop_point">
                                                                        <v-icon class="mr-2">mdi-star-plus
                                                                        </v-icon>
                                                                        {{ $t('รับคะแนน') }}
                                                                    </template>
                                                                    <template v-else>
                                                                        <v-icon class="mr-2"></v-icon>
                                                                        {{ $t('สมัครสมาชิกร้าน') }}
                                                                    </template>
                                                                </v-btn>
                                                            </template>
                                                        </claim-order-component>
                                                    </template>
                                                </div>
                                            </div>
                                        </v-alert>
                                    </template>
                                    <v-alert v-else-if="cpFlagIsLogin && !isUserJoin"
                                             class="mt-4"
                                             text
                                             color="#999">
                                        <div class="just_flex align-center"
                                             style="gap: 10px;">
                                            <span style="font-size: 0.7em">{{ $t('สนใจเป็นสมาชิกเพื่อรับสิทธิพิเศษ') }} ?</span>
                                            <join-dialog>
                                                <template v-slot:activator="{on,attrs}">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           small
                                                           text
                                                           class="my-auto"
                                                           color="primary">
                                                        {{ $t('Join เป็นสมาชิกร้าน') }}
                                                    </v-btn>
                                                </template>
                                            </join-dialog>
                                        </div>
                                    </v-alert>
                                    <template v-if="canReview">
                                        <v-alert color="yellow lighten-4"
                                                 class="align-center amber--text text--darken-2">
                                            <div class="just_flex flex-wrap">
                                                <span class="pl-3">
                                                    {{ $t('การรีวิวของคุณมีประโยชน์อย่างยิ่งกับเราและผู้ซื้อ') }}
                                                </span>
                                                <div :class="(isDesktop)?'d-inline-block':'flex-grow-1 d-block mt-4'">
                                                    <v-btn :href="orderData.review_url"
                                                           :dense="isDesktop"
                                                           :text="isDesktop"
                                                           :outlined="!isDesktop"
                                                           :large="!isDesktop"
                                                           block
                                                           color="orange"
                                                           style="vertical-align: top; font-size: 16px;">
                                                        <v-icon color="orange"
                                                                class="mr-2">mdi-star
                                                        </v-icon>
                                                        {{ $t('เขียนรีวิว') }}
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-alert>
                                    </template>
                                    <template v-if="canSubscribeRN">
                                        <fb-recurring-notification></fb-recurring-notification>
                                    </template>
                                    <template v-if="isStatusIsWaiting">
                                        <v-divider class="mb-4"></v-divider>
                                        <v-row>
                                            <div class="minor mb-5">{{ $t('กรุณาคัดลอก Link บิลนี้และบันทึกไว้กับตนเอง เพื่อให้คุณสามารถกลับมาติดตามสถานะได้อีกครั้ง') }}</div>
                                            <v-text-field readonly
                                                          @focus="selectAllText"
                                                          hide-details="auto"
                                                          outlined
                                                          :value="currentURL">
                                                <template v-slot:prepend-inner>
                                                    <v-icon class=" ml-1 mr-2">mdi-link</v-icon>
                                                </template>
                                                <template v-slot:append>
                                                    <handle-copy-component class="d-inline-block ml-1"
                                                                           ref="handleCopy">
                                                        <v-btn color="primary"
                                                               text
                                                               small
                                                               style="margin-top: -0.25rem;"
                                                               v-clipboard:copy="currentURL">
                                                            <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                                                            <template v-if="isDesktop">Copy</template>
                                                        </v-btn>
                                                    </handle-copy-component>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                    </template>
                                </div>
                            </template>
                            <template v-if="orderStatusMeta && !(status != 'wait_order' || isShopClose)">
                                <div v-if="status === 'wait_order' && orderData.order_message[orderStatusMeta.status]" class="default-container px-0 pb-0">
                                    <v-alert class="my-0"
                                             color="primary"
                                             :style="isDesktop?'':'margin: 0 1rem;'"
                                             icon="mdi-information-outline"
                                             text>
                                        {{ orderData.order_message[orderStatusMeta.status] }}
                                    </v-alert>
                                </div>
                            </template>
                            <!-- เข้าทุกกรณียกเว้น เป็น cart และร้านปิดอยู่ -->
                            <template v-if="!isShopClose
                                            || (status !== 'wait_order' && status !== 'wait_payment')">
                                <!--suppress XmlDuplicatedId -->
                                <div id="beginStepInfo"
                                     ref="beginStepInfo"></div>
                                <div class="stepContainer">
                                    <step :style="'order:' + parseInt(stepMapping.buyerInfo)"
                                          :step-number="parseInt(stepMapping.buyerInfo)"
                                          :step-title="(!isPassStepBuyerInfo)? $t('การติดต่อ') : $t('ข้อมูลติดต่อ')"
                                          @editBtnClicked="$refs.buyerInfo.editBuyerInfo()">
                                        <div>
                                            <!--                                <buyer-info ref="buyerInfo"></buyer-info>-->
                                            <contact-info ref="buyerInfo"
                                                          @doToggleDrawer="drawer = !drawer"></contact-info>
                                        </div>
                                    </step>
                                    <step :style="'order:' + parseInt(stepMapping.shippingMethod)"
                                          :step-number="parseInt(stepMapping.shippingMethod)"
                                          :step-title="(!isPassStepShippingMethod)? $t('การจัดส่ง') : $t('ข้อมูลจัดส่ง')"
                                          @goToShippingType="goToShippingType">
                                        <shipping-method ref="shippingInfo"></shipping-method>
                                    </step>
                                    <step :style="'order:' + parseInt(stepMapping.paymentMethod)"
                                          :step-number="parseInt(stepMapping.paymentMethod)"
                                          :step-title="(!isPassStepPaymentMethod)?$t('การชำระเงิน'):$t('ข้อมูลชำระเงิน')">
                                        <payment-method :isExpandOrderDetail="expandOrderDetail"
                                                        @doExpandOrderDetail="onDoExpandOrderDetail"></payment-method>
                                    </step>
                                    <template v-if="status == 'wait_order' && !isPassAllStep && isAllShrink">
                                        <div class="lnwme-container mt-4"
                                             style="order: 4;">
                                            <v-alert style="cursor: pointer;"
                                                     color="primary"
                                                     type="info">
                                                <div class="just_flex"
                                                     @click="expandPaymentMethod()">
                                                    <span>{{ $t('กรุณากรอกข้อมูลตามลำดับให้ครบถ้วน') }}</span>
                                                    <v-icon>mdi-arrow-up</v-icon>
                                                </div>
                                            </v-alert>
                                        </div>
                                    </template>
                                </div>

                                <!-- แสดง progress bar / Timeline -->
                                <template v-if="status !== 'wait_order'">
                                    <div class="default-container status-description elevation-24 mt-12">
                                        <div class="just_flex my-4">
                                            <b style="font-size: 1.5em">{{ $t('บิลที่') }} {{ displayOrderID(orderData.order_id) }}
                                                <handle-copy-component class="d-inline-block ml-1"
                                                                       ref="handleCopy">
                                                    <v-btn color="primary"
                                                           text
                                                           x-small
                                                           v-clipboard:copy="orderData.order_id">
                                                        <v-icon x-small>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </handle-copy-component></b>
                                            <v-dialog width="400">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="#666"
                                                           text
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon class="mr-2"
                                                                small>mdi-qrcode-scan
                                                        </v-icon>
                                                        {{ $t('แสดง') }} QR
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <img style="width: 400px; max-width: 100%; padding: 2rem;"
                                                         :src="getQrUrl()"
                                                         :lazy-src="require('@/assets/lnw_logo_placeholder.jpg')"/>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                        <v-row>
                                            <div class="minor mb-5">{{ $t('กรุณาคัดลอก Link บิลนี้และบันทึกไว้กับตนเอง เพื่อให้คุณสามารถกลับมาติดตามสถานะได้อีกครั้ง') }}</div>
                                            <v-text-field readonly
                                                          @focus="selectAllText"
                                                          hide-details="auto"
                                                          outlined
                                                          :value="currentURL">
                                                <template v-slot:prepend-inner>
                                                    <v-icon class=" ml-1 mr-2">mdi-link</v-icon>
                                                </template>
                                                <template v-slot:append>
                                                    <handle-copy-component class="d-inline-block ml-1"
                                                                           ref="handleCopy">
                                                        <v-btn color="primary"
                                                               text
                                                               small
                                                               style="margin-top: -0.25rem;"
                                                               v-clipboard:copy="currentURL">
                                                            <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                                                            <template v-if="isDesktop">Copy</template>
                                                        </v-btn>
                                                    </handle-copy-component>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                        <v-timeline align-top
                                                    clipped
                                                    dense>
                                            <v-slide-x-transition group>
                                                <v-timeline-item v-for="item in timelineAllStepsWithMeta"
                                                                 :key="item.step"
                                                                 :large="item.tense === 'present'"
                                                                 :color="(item.tense === 'present')?orderStatusMeta.color:'white'">
                                                    <template v-slot:icon>
                                                        <div :class="item.colorize_flag">
                                                            <img :class="(item.colorize_flag)?'':'disabled'"
                                                                 :style="(item.tense === 'present')?'filter : grayscale(100%) brightness(100); width: 22px; height: 22px;':''"
                                                                 style="width: 16px; height: 16px;"
                                                                 :src="require('../assets/order_status/' + item.step + '.png')">
                                                        </div>
                                                    </template>
                                                    <div :class="{important_data:(item.tense === 'present'),disabled:(item.tense === 'future')}">
                                                        <div v-if="item.time === true"></div>
                                                        <v-tooltip v-else-if="item.time"
                                                                   top>
                                                            <template v-slot:activator="{ on }">
                                                                    <span class="minor"
                                                                          v-on="on">{{ clock.diff_now_text(item.time) }}</span>
                                                            </template>
                                                            {{ clock.show_datetime(item.time) }}
                                                        </v-tooltip>
                                                        <div v-else-if="item.tense === 'present'"
                                                             class="minor black--text">{{ $t('ขณะนี้') }}
                                                        </div>
                                                        <div :class="(item.tense === 'present')?orderStatusMeta.text_color:''">{{ (item.tense === 'past') ? orderStatusMetaDatas[item.step].text_already : orderStatusMetaDatas[item.step].text }}</div>
                                                    </div>
                                                </v-timeline-item>
                                            </v-slide-x-transition>
                                        </v-timeline>
                                        <v-divider class="my-4"></v-divider>
                                        <v-row style="margin: 0 -12px;">
                                            <v-col cols="12"
                                                   :sm="(orderLanguage == 'th')?4:12">
                                                <v-btn block
                                                       color="primary"
                                                       large
                                                       elevation="0"
                                                       @click="orderBuyAgain()"
                                                       :loading="isAjaxLoading"
                                                       :disabled="isAjaxLoading">
                                                    <v-icon class="mr-2">mdi-repeat-variant
                                                    </v-icon>
                                                    {{ $t('สั่งซื้ออีกครั้ง') }}
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12"
                                                   :sm="(orderLanguage == 'th')?4:12">
                                                <v-btn block
                                                       color="primary"
                                                       large
                                                       :text="isDesktop"
                                                       :outlined="!isDesktop"
                                                       elevation="0"
                                                       @click="(existingOrderOwner)?(isLoginRequired = true):$router.push('/order/' + shopData.web_name)">
                                                    <v-icon class="mr-2">mdi-shopping
                                                    </v-icon>
                                                    {{ $t('ประวัติการซื้อ') }}
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12"
                                                   :sm="(orderLanguage == 'th')?4:12">
                                                <template v-if="canCancelOrder">
                                                    <v-bottom-sheet v-model="cancelSheet">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn block
                                                                   color="primary"
                                                                   large
                                                                   :text="isDesktop"
                                                                   :outlined="!isDesktop"
                                                                   elevation="0"
                                                                   v-bind="(!existingOrderOwner)?attrs:''"
                                                                   v-on="(!existingOrderOwner)?on:''"
                                                                   @click="(existingOrderOwner)?(isLoginRequired = true):''">
                                                                <v-icon class="mr-2">mdi-cancel
                                                                </v-icon>
                                                                {{ $t('ยกเลิกออเดอร์') }}
                                                            </v-btn>
                                                        </template>
                                                        <v-sheet class="text-center"
                                                                 height="200px">
                                                            <div class="pt-12 pb-6 px-6">
                                                                {{ $t('แน่ใจหรือไม่ที่จะยกเลิกออเดอร์นี้ ? สินค้าของคุณจะถูกยกเลิกการจอง และอาจหมดได้นะคะ') }}
                                                            </div>
                                                            <v-btn class=""
                                                                   color="error"
                                                                   @click="clickConfirmCancelOrder">
                                                                {{ $t('ยืนยันการยกเลิก') }}
                                                            </v-btn>
                                                            <v-btn class="ml-6"
                                                                   text
                                                                   @click="cancelSheet = !cancelSheet">
                                                                {{ $t('ปิดหน้าต่าง') }}
                                                            </v-btn>
                                                        </v-sheet>
                                                    </v-bottom-sheet>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                                <!-- แสดง Chat เมื่อ order แล้ว -->
                                <div v-if="status !== 'wait_order' && status !== 'wait_payment' && (showChatComponent || orderData.dispute_url)"
                                     class="lnwme-container completed closed"
                                     id="chatStep">
                                    <div class="stepHeader">
                                        <div class="stepL">
                                        <span class="circle primary flex_center">
                                            <v-icon color="white"
                                                    :size="(isDesktop)?18:13">
                                                mdi-comment-question
                                            </v-icon>
                                        </span>
                                            <span style="white-space: nowrap">{{ $t('สอบถาม') }} / {{ $t('ช่วยเหลือ') }}</span>
                                        </div>
                                        <div class="stepR">
                                            <template v-if="showChatComponent">
                                                <v-btn text
                                                       @click="scrollTo('#lastMessage')">{{ $t('ล่าสุด') }}
                                                    <v-icon class="ml-2"
                                                            small>mdi-arrow-down
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="stepContent">
                                        <v-card elevation="24">
                                            <template v-if="showChatComponent">
                                                <ChatComponent></ChatComponent>
                                            </template>
                                            <template v-else-if="orderData.dispute_url">
                                                <v-alert text
                                                         class="mb-0"
                                                         color="white">
                                                    <v-row align="center">
                                                        <v-col class="grow grey--text text-body-2"
                                                               :style="(!isDesktop)?'flex-basis:100%':''">
                                                            {{ $t('หากคุณต้องการความช่วยเหลือ กรุณาแจ้งข้อร้องเรียนให้ร้านค้าและ LnwPay ทราบ') }}
                                                        </v-col>
                                                        <v-col class="shrink">
                                                            <v-btn color="primary"
                                                                   large
                                                                   :href="orderData.dispute_url">{{ $t('แจ้งข้อร้องเรียน') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-alert>
                                            </template>
                                        </v-card>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <!-- กล่องแสดงรายละเอียด order ด้านล่างสุด -->
                        <the-order-detail v-if="!isShowOrderDetailOnTop"></the-order-detail>
                    </template>
                    <div class="footer">
                        <footer-component></footer-component>
                    </div>
                </div>
            </div>
        </template>
        <!----------- KPLUS --------------->
        <kplus-waiting-payment></kplus-waiting-payment>
        <!--
        <iframe ref="kplus_iframe"
                class="m-0" src="about:blank"
                style="visibility: hidden; position: absolute; left: 0; top: 0;" width="1" height="1"></iframe>
        -->
    </v-sheet>
</template>
<style lang="scss">
#nav {
  display: none;
}
</style>
<script>
/* eslint no-use-before-define: 0 */
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import Step from "../components/Order/Step";
// import BuyerInfo from "../components/Order/BuyerInfo";
import ContactInfo from "../components/Order/ContactInfo";
import JoinDialog from "@/components/Order/components/JoinComponent.vue";
import ShippingMethod from "../components/Order/ShippingMethod";
import PaymentMethod from "../components/Order/PaymentMethod";
import MixinsFormCouponCode from "../components/Order/mixins/MixinsFormCouponCode";
import Helper from "../libraries/Helper";
import {clock} from "../libraries/DateTime";
import {
    SET_IS_AJAX_RESPONSE,
    SET_IS_AJAX_RESPONSE_MESSAGE,
    SET_LNWPAY_CALLBACK_STATES
} from "@/store/modules/order/mutation-types.js";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import TheOrderDetail from "@/components/Order/TheOrderDetail.vue";
import TheOrderItems from "@/components/Order/TheOrderItems.vue";
import TheOrderDetailFixedTop from "@/components/Order/TheOrderDetailFixedTop.vue";
import TheUserDrawer from "@/components/Order/TheUserDrawer.vue";
import MixinsFormExpandOrderDetail from "@/components/Order/mixins/MixinsFormExpandOrderDetail.vue";
import TheLoginRequired from "@/components/Order/TheLoginRequired.vue";
import LnwPayCallBack
    from "@/components/Order/components/PaymentMethod/components/LnwPaymentComponent/components/LnwPayCallBack.vue";
import ConfirmOrderDialog from "@/components/Order/components/ConfirmOrderComponent.vue";
import HandleCopyComponent from "@/components/Order/components/HandleCopyComponent";
import ChatComponent from "@/components/Order/components/ChatComponent";
import ClaimOrderComponent from "@/components/Order/components/ClaimOrderComponent";
import FooterComponent from "@/components/Order/components/FooterComponent";
import KplusWaitingPayment from "@/components/Order/components/PaymentMethod/KplusWaitingPayment.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import {setLocale} from "@/i18n";
import {loadScript} from "@/packages/lnw-vue-gtag/util.js";
import FbCheckboxPlugin from "@/components/Order/components/FbCheckboxPlugin";
import PageNotFound from "@/components/Order/PageNotFound";
import store from "@/store";
import FbRecurringNotification from "@/components/Order/components/FbRecurringNotification.vue";
import MixinsHandleShopData from "@/components/Order/mixins/MixinsHandleShopData";
import MixinsContactMeta from "@/mixins/MixinsContactMeta";
import ContactButton from "@/components/Order/ContactButton";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual";
import Faqs from "@/components/Order/components/faqs"
import ItemRow from "@/components/Order/components/ItemRow";
import AddMoreItems from "@/components/Order/components/AddMoreItems";

const config_lnwpay = require('@/config/lnwpay.json');

// const config_app = require('@/config/app.json');

export default {
    name: 'order',
    mixins: [
        MixinsDisabledIconFocus,
        MixinsFormCouponCode,
        MixinsFormExpandOrderDetail,
        MixinsTheOrderDetailMutual,
        MixinsHelper,
        MixinsHandleShopData,
        MixinsContactMeta
    ],
    // metaInfo(){
    //     let title = config_app.default_title;
    //     let desc = '';
    //     let meta = [];
    //     if(this.orderData){
    //         if(this.orderData.order_id){
    //             title = this.$t('บิลที่') + " " + this.orderData.order_id;
    //         }else{
    //             title = this.$t('บิลออนไลน์');
    //         }
    //         if(this.orderStatusMeta){
    //             title += ` - ${this.orderStatusMeta.text}`;
    //             desc = this.orderStatusMeta.desc;
    //         }
    //         if(this.shopData){
    //             meta = [
    //                 {name: 'description', content: desc},
    //                 {property: 'og:title', content: title},
    //                 {property: 'og:description', content: desc},
    //                 {property: 'og:type', content: 'website'},
    //                 {property: 'og:image', content: this.shopData.avatar}
    //             ];
    //         }
    //     }
    //
    //     return {
    //         title,
    //         meta
    //     };
    // },
    props: {
      type: {type: String, required: false},
    },
    data(){
        return {
            devel: false,
            progressBar: 0,
            loader: null,
            drawer: null,
            reviews: [
                {status: 'wait_review', text: this.$t('รอรีวิว'), quantity: 2},
                {status: 'reviewed', text: this.$t('รีวิวแล้ว'), quantity: 28},
            ],
            cancelSheet: false,
            fadeInfo: true,
            fadeBanner: false, //ตอนนี้ปรับให้แสดงตลอดเวลา
            confirmDialog: false,
            showChatComponent: false,
            showAdminButton: false,
            kplus_interval_id: null,
            timestamp: null,
            shouldShowLnwPayDialog: false,
            forceHideLnwPayDialog: false,
        };
    },
    components: {
        AddMoreItems,
        Faqs,
        PageNotFound,
        DebugIndicator,
        VDialog,
        JoinDialog,
        VBottomSheet,
        Step,
        // BuyerInfo,
        ContactInfo,
        ShippingMethod,
        PaymentMethod,
        TheOrderDetail,
        TheOrderItems,
        TheOrderDetailFixedTop,
        TheUserDrawer,
        TheLoginRequired,
        LnwPayCallBack,
        ConfirmOrderDialog,
        HandleCopyComponent,
        ChatComponent,
        ClaimOrderComponent,
        KplusWaitingPayment,
        FbCheckboxPlugin,
        FbRecurringNotification,
        ContactButton,
        FooterComponent,
        ItemRow
    },
    computed: {
        ...mapGetters({
            order_form: 'order/form',
            appliedCoupons: 'order/appliedCoupons',
            isProtectedByLnwPay: 'order/isProtectedByLnwPay',
            isShopIsTestShop: 'order/isShopIsTestShop',
            isOwnerCreateFlag: 'order/isOwnerCreateFlag',
            isShopClose: 'order/isShopClose',
            mall: 'order/mall',
            isRealMall: 'order/isRealMall',
            gointer_enabled: 'order/gointer',
            orderLanguage: 'order/orderLanguage',
            canAddCoupon: 'order/canAddCoupon',
            canRemoveCoupon: 'order/canRemoveCoupon',
            myCoupons: 'order/myCoupons',
            canEditBuyerInfo: 'order/canEditBuyerInfo',
            canEditProductQuantity: 'order/canEditProductQuantity',
            canEditProductDetail: 'order/canEditProductDetail',
            canEditOrderDetail: 'order/canEditOrderDetail',
            canEditShippingMethod: 'order/canEditShippingMethod',
            canCancelOrder: 'order/canCancelOrder',
            canConfirmReceived: 'order/canConfirmReceived',
            canReview: 'order/canReview',
            reviewUrl: 'order/reviewUrl',
            isPassStepBuyerInfo: 'order/isPassStepBuyerInfo',
            isPassStepShippingMethod: 'order/isPassStepShippingMethod',
            isPassStepPaymentMethod: 'order/isPassStepPaymentMethod',
            isShippingIsLastStep: 'order/isShippingIsLastStep',
            isWaitingPacking: 'order/isWaitingPacking',
            isWaitingPickup: 'order/isWaitingPickup',
            isPassAllStep: 'order/isPassAllStep',
            stepMapping: 'order/stepMapping',
            isWaitReceiverInfo: 'order/isWaitReceiverInfo',
            isOrderPickupType: 'order/isOrderPickupType',
            isWaitingShippingPrice: 'order/isWaitingShippingPrice',
            isShowOrderDetailOnTop: 'order/isShowOrderDetailOnTop',
            facebookCheckboxPluginUserRef: 'order/facebookCheckboxPluginUserRef',
            creditCardJsSDK: 'order/creditCardJsSDK',
            callbackStates: 'order/lnwpayCallbackStates',
            callbackStatesJustEntered: 'order/lnwpayCallbackStatesJustEntered',
            cpFlagIsLogin: 'user/isLogin',
            isUserJoin: 'order/isUserJoin',
            selected_shipping: 'order/selectedShipping',
        }),
        ...mapState({
            remarkDialogOrderValue: state => state.order.orderData ? state.order.orderData.detail : null,
            userData: state => state.user.userData,
            existingOrderOwner: state => state.order.existingOrderOwner,
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            isShowOverlay: state => state.order.isShowOverlay,
            isShowOverlayPayment: state => state.order.isShowOverlayPayment,
            isAjaxLoading: state => state.order.isAjaxLoading,
            status: state => state.order.orderData?.order_status,
            kplusDialogFlag: state => state.order.kplusDialogFlag,
            stepMetas: state => state.order.stepMetas,
        }),
        isAllShrink(){
            return Object.values(this.stepMetas).every(value => !value.open);
        },
        isAjaxResponse: {
            get(){
                return this.$store.state.order.isAjaxResponse;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE, value);
            },
        },
        isAjaxResponseMessage: {
            get(){
                return this.$store.state.order.isAjaxResponseMessage;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE_MESSAGE, value);
            },
        },
        computedResponseMessage(){
            if(this.$t(this.isAjaxResponseMessage) != ''){
                return this.$t(this.isAjaxResponseMessage);
            }
            if(this.$t('BUY-' + this.isAjaxResponseMessage) != ''){
                return this.$t('BUY-' + this.isAjaxResponseMessage);
            }
            if(this.$t('CART-' + this.isAjaxResponseMessage) != ''){
                return this.$t('CART-' + this.isAjaxResponseMessage);
            }
            return this.isAjaxResponseMessage;
        },
        showCart: {
            set(value){
                this.$store.commit('order/setForm', {key: 'show_cart', value: value});
            },
            get(){
                return this.$store.state.order.form.show_cart;
            },
        },
        paymentInputState: {
            get(){
                return this.order_form.paymentMethodInputValue;
            },
            set(newValue){
                this.storeActionSetForm({paymentMethodInputValue: newValue});
            },
        },
        isCopied: {
            get(){
                return this.$store.state.order.isCopied;
            },
            set(value){
                this.$store.dispatch('order/setIsCopied', value);
            },
        },
        activeStatus(){
            return this.timelineAllStepsWithMeta.filter(function (item){
                return item.tense === 'present';
            })[0];
        },
        orderStatusMeta(){
            if(this.status){
                let status = this.status;
                if(this.isWaitingForChangeStatus){
                    status = 'wait_payment_verify'
                }else if(this.isWaitingShippingPrice){
                    status = 'wait_order_confirm'
                }else if(this.isWaitReceiverInfo){
                    status = 'wait_receiver_info'
                }else if(this.isWaitingPacking){
                    status = 'wait_packing'
                }else if(this.isWaitingPickup){
                    status = 'wait_pickup'
                }
                return this.orderStatusMetaDatas[status];
            }
            return false;
        },
        isShowOrderStatusTopBanner(){
            if(this.status && typeof this.orderStatusMetaDatas[this.status] !== 'undefined'){
                if((this.status !== 'wait_order' && !this.fadeBanner) //กรณียัง fade อยู่จะไม่แสดง
                    || (this.status == 'wait_order' && this.orderData.expired_time) //กรณียังไม่ยืนยันและมีวันหมดอายุ - จะมาจากที่ร้านสร้างบิลเอง
                ){
                    return true;
                }
            }
            return false;
        },
        isStatusIsWaiting(){
            return ['wait_payment_verify', 'wait_send', 'wait_packing', 'wait_pickup', 'wait_confirm', 'wait_receive'].includes(this.status);
        },
        orderStatusMetaDatas(){
            return {
                wait_order: {
                    status: 'wait_order',
                    time_key: 'order_time',
                    text: this.$t('รอยืนยันรายการ'),
                    text_already: this.$t('คุณได้สร้างรายการสั่งซื้อแล้ว'),
                    banner: this.$t('กรุณายืนยันรายการ'),
                    desc: '',
                    color: 'primary',
                    text_color: 'white--text'
                },
                wait_order_confirm: {
                    status: 'wait_order_confirm',
                    time_key: 'order_time',
                    text: this.$t('รอร้านยืนยันรายการ'),
                    text_already: this.$t('ร้านยืนยันรายการแล้ว'),
                    banner: this.$t('กรุณารอร้านค้ายืนยันรายการ'),
                    desc: this.$t('ร้านค้าได้รับข้อมูลการสั่งซื้อของคุณเรียบร้อยแล้ว และกำลังตรวจสอบรายการสินค้าพร้อมคิดราคาค่าจัดส่ง') + this.$t('กรุณารอดำเนินการ และกลับมาเช็คสถานะที่นี่ใหม่อีกครั้ง'),
                    color: 'teal darken-1',
                    text_color: 'teal--text text--darken-1'
                },
                wait_payment: {
                    status: 'wait_payment',
                    time_key: 'informpayment_time',
                    text: this.$t('รอชำระเงิน'),
                    text_already: this.$t('คุณได้แจ้งชำระเงินแล้ว'),
                    button_text: this.$t('ชำระเงิน'),
                    banner: this.$t('กรุณาชำระเงิน'),
                    desc: this.$t('กรุณาชำระเงินจำนวน') + ' ' + Helper.number_format(this.orderData.price.pay, 2) + ' ' + this.$t('บาท') + ' ' + ((this.orderData.payment_provider && this.orderData.payment_provider.search('bank_transfer') != -1) ? this.$t('และแจ้งโอน (กรณีโอนเงินบัญชีธนาคาร)') : '') + ' ' + ((this.orderData && this.orderData.expired_time) ? this.$t('ภายใน') + ' ' + this.$d(new Date(this.orderData.expired_time.replace(/-/g, "/")), 'long') + ((this.$i18n.locale == 'th') ? ' น.' : '') : ' '),
                    color: 'green accent-4',
                    text_color: 'green--text text--accent-4'
                },
                wait_payment_verify: {
                    status: 'wait_payment_verify',
                    time_key: 'acceptpayment_time',
                    text: this.$t('รอร้านยืนยันยอด'),
                    text_already: this.$t('ร้านยืนยันยอดเงินแล้ว'),
                    button_text: ((this.isShippingIsLastStep) ? this.$t('ระบุที่อยู่ผู้รับ') : this.$t('แจ้งโอนใหม่')),
                    banner: this.$t('รอร้านค้ายืนยันยอดเงิน'),
                    desc: this.$t('ร้านค้าได้รับข้อมูลการแจ้งชำระเงินของคุณเรียบร้อยแล้ว และกำลังตรวจสอบยอดเงินที่แจ้งเพื่อยืนยันการสั่งซื้อของคุณ') + ((this.isLnwPay) ? ' (' + this.$t('ดำเนินการโดย LnwPay') + ') ' : ' ') + ((this.isShippingIsLastStep) ? this.$t('ทั้งนี้คุณสามารถเพิ่มที่อยู่ผู้รับในขณะที่รอร้านดำเนินการได้') : this.$t('กรุณารอดำเนินการ และกลับมาเช็คสถานะที่นี่ใหม่อีกครั้ง')),
                    color: 'orange darken-1',
                    text_color: 'orange--text text--darken-1'
                },
                wait_receiver_info: {
                    status: 'wait_receiver_info',
                    text: this.$t('รอระบุที่อยู่ผู้รับ'),
                    text_already: this.$t('ระบุที่อยู่ผู้รับแล้ว'), //น่าจะไม่ได้แสดงที่ไหน
                    button_text: this.$t('ระบุที่อยู่'),
                    banner: this.$t('กรุณาระบุที่อยู่ผู้รับสินค้า'),
                    desc: this.$t('กรุณาระบุที่อยู่ผู้รับสินค้าของคุณ เพื่อให้ร้านจัดส่งสินค้าได้อย่างถูกต้อง'),
                    color: 'red darken-1',
                    text_color: 'red--text text--darken-1'
                },
                wait_send: {
                    status: 'wait_send',
                    time_key: 'send_time',
                    text: this.$t('รอจัดส่ง'),
                    text_already: this.$t('ร้านจัดส่งสินค้าแล้ว'),
                    button_text: this.$t('ดูระยะเวลาส่ง'),
                    banner: this.$t('รอร้านค้าแพ็คและจัดส่งสินค้า'),
                    desc: this.$t('ร้านค้ายืนยันการสั่งซื้อเรียบร้อยแล้ว และอยู่ในระหว่างการแพ็คสินค้าเพื่อจัดส่งไปยังผู้ให้บริการขนส่ง กรุณากลับเข้ามาอัพเดทสถานะใหม่อีกครั้ง'),
                    color: 'amber darken-1',
                    text_color: 'amber--text text--darken-1'
                },
                wait_packing: {
                    status: 'wait_packing',
                    time_key: 'send_time',
                    text: this.$t('รอแพ็คสินค้า'),
                    text_already: this.$t('ร้านแพ็คสินค้าแล้ว'),
                    button_text: this.$t('ดูระยะเวลาแพ็ค'),
                    banner: this.$t('รอร้านค้าแพ็คสินค้า'),
                    desc: this.$t('ร้านค้ายืนยันการสั่งซื้อเรียบร้อยแล้ว และอยู่ในระหว่างการแพ็คสินค้า กรุณากลับเข้ามาอัพเดทสถานะใหม่อีกครั้ง'),
                    color: 'amber darken-1',
                    text_color: 'amber--text text--darken-1'
                },
                sent: {
                    status: 'sent',
                    text: this.$t('จัดส่งแล้ว'),
                    text_already: this.$t('จัดส่งแล้ว'),
                    button_text: this.$t('ดูรหัสพัสดุ'),
                    banner: this.$t('ร้านค้าจัดส่งสินค้าแล้ว'),
                    desc: this.$t('ร้านค้าได้จัดส่งสินค้าไปยังผู้ขนส่งเรียบร้อยแล้ว กรุณารอรับสินค้า ขอบคุณและหวังว่าจะได้รับคำสั่งซื้อจากคุณอีกครั้ง'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3',
                    last: true,
                },
                wait_receive: {
                    status: 'wait_receive',
                    time_key: 'receive_time',
                    text: this.$t('รอรับสินค้า'),
                    text_already: this.$t('พัสดุถึงผู้รับเรียบร้อยแล้ว'),
                    button_text: this.$t('ติดตามพัสดุ'),
                    banner: this.$t('จัดส่งแล้ว กรุณายืนยันหากได้รับสินค้าแล้ว'),
                    desc: this.$t('ร้านค้าได้จัดส่งพัสดุแล้ว กรุณารอรับสินค้าจากผู้ให้บริการขนส่ง คุณสามารถตรวจสอบและติตตามพัสดุได้ที่ข้อมูลการจัดส่งด้านล่างนี้'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3'
                },
                wait_confirm: {
                    status: 'wait_confirm',
                    time_key: 'confirm_time',
                    text: this.$t('รอยืนยันรับสินค้า'),
                    text_already: this.$t('คุณยืนยันรับสินค้าแล้ว'),
                    button_text: this.$t('ยืนยันรับสินค้า'),
                    banner: this.$t('สินค้าถึงผู้รับแล้ว กรุณายืนยันหากได้รับสินค้าแล้ว'),
                    desc: this.$t('ระบบตรวจสอบจากรหัสพัสดุ พบว่าพัสดุได้ส่งถึงมือผู้รับแล้ว กรุณายืนยันหากคุณได้รับสินค้าแล้ว') + ((this.orderData && this.orderData.confirm_expired_time) ? this.$t('ภายใน') + ' ' + this.$d(new Date(this.orderData.confirm_expired_time.replace(/-/g, "/")), 'long') : '') + ' ' + this.$t('หรือหากมีปัญหาในการรับพัสดุ กรุณาแจ้งข้อร้องเรียน เพื่อให้ทีมงานตรวจสอบอีกครั้ง'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3'
                },
                wait_pickup: {
                    status: 'wait_pickup',
                    time_key: 'confirm_time',
                    text: this.$t('สินค้าพร้อมแล้ว'),
                    text_already: this.$t('คุณเข้ารับสินค้าแล้ว'),
                    button_text: this.$t('พร้อมเข้ารับแล้ว'),
                    banner: this.$t('สินค้าถึงสาขาพร้อมเข้ารับแล้ว กรุณามารับสินค้าตามที่กำหนด'),
                    desc: this.$t('ร้านค้าได้จัดเตรียมพัสดุเรียบร้อยแล้ว พร้อมให้คุณเข้ารับสินค้าตามสาขาที่คุณได้เลือกไว้ ขอบคุณและหวังว่าจะได้รับคำสั่งซื้อจากคุณอีกครั้ง'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3'
                },
                completed: {
                    status: 'completed',
                    text: this.$t('เสร็จเรียบร้อย'),
                    text_already: '',
                    button_text: this.$t('สั่งซื้ออีกครั้ง'),
                    banner: this.$t('การสั่งซื้อเสร็จเรียบร้อยแล้ว') + ' Thank you.',
                    desc: this.$t('รายการสั่งซื้อนี้เสร็จสิ้นแล้ว คุณได้รับสินค้าครบถ้วน และร้านค้าได้รับเงินเป็นที่เรียบร้อยแล้ว ขอบคุณและหวังว่าจะได้รับคำสั่งซื้อจากคุณอีกครั้ง'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3',
                    last: true,
                },
                expired: {
                    status: 'expired',
                    text: this.$t('หมดอายุ'),
                    text_already: this.$t('หมดอายุ'),
                    button_text: this.$t('สั่งซื้ออีกครั้ง'),
                    banner: this.$t('รายการสั่งซื้อนี้หมดอายุแล้ว'),
                    desc: this.$t('คุณทำรายการสั่งซื้อไม่เสร็จสิ้นก่อนวันที่กำหนด กรุณาสั่งซื้ออีกครั้ง'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                refund: {
                    status: 'refund',
                    text: this.$t('คืนเงิน'),
                    text_already: this.$t('คืนเงิน'),
                    button_text: this.$t('สั่งซื้ออีกครั้ง'),
                    banner: this.$t('รายการสั่งซื้อนี้คืนเงิน'),
                    desc: this.$t('ร้านค้าคืนเงินแล้ว'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                buyer_cancelled: {
                    status: 'buyer_cancelled',
                    text: this.$t('ยกเลิก'),
                    text_already: this.$t('ยกเลิก'),
                    button_text: this.$t('สั่งซื้ออีกครั้ง'),
                    banner: this.$t('ยกเลิกรายการสั่งซื้อนี้แล้ว'),
                    desc: this.$t('คุณได้ยกเลิกรายการสั่งซื้อนี้แล้ว'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                seller_cancelled: {
                    status: 'seller_cancelled',
                    text: this.$t('ถูกยกเลิก'),
                    text_already: this.$t('ถูกยกเลิก'),
                    button_text: this.$t('สั่งซื้ออีกครั้ง'),
                    banner: this.$t('รายการสั่งซื้อนี้ถูกยกเลิก'),
                    desc: this.$t('ร้านค้าได้ยกเลิกรายการสั่งซื้อนี้แล้ว'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                suspended: {
                    status: 'suspended',
                    text: this.$t('ร้องเรียน'),
                    text_already: '',
                    banner: this.$t('รายการสั่งซื้อนี้ถูกระงับ'),
                    desc: this.$t('คุณร้องเรียนรายการสั่งซื้อนี้'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
            };
        },
        timelineAllStepsWithMeta(){
            const steps = [];
            for(let step of this.orderData.state_steps) {

                const s = {};
                if(step.tense === 'present'
                    && this.isWaitingShippingPrice){
                    s.step = 'wait_order_confirm';
                }else if(step.tense === 'present'
                    && this.isWaitReceiverInfo){
                    s.step = 'wait_receiver_info';
                }else if(step.key === 'wait_send'
                    && this.isOrderPickupType){
                    s.step = 'wait_packing';
                }else if((step.key === 'completed' || step.key === 'sent')
                    && this.isOrderPickupType){
                    s.step = 'wait_pickup';
                }else{
                    s.step = step.key;
                }
                s.time = step.time;
                s.tense = step.tense; // past , present , future
                s.colorize_flag = step.tense === 'past' || step.tense === 'present'; // 'present', 'future',
                steps.push(s);
            }

            return steps;
        },
        isLnwPay(){
            return this.$store.state.order.orderData.isProtectedByLnwPay;
        },
        canEditCart(){
            return this.canEditProductDetail || this.canEditProductQuantity;
        },
        clock(){
            return clock;
        },
        isShowLnwPayDialog(){
            return this.shouldShowLnwPayDialog && !this.forceHideLnwPayDialog;
        },
        isWaitingForChangeStatus(){
            return (this.callbackStates?.pay_s === 'success' || this.callbackStates?.pay_s === 'pending')
                && (this.timestamp - config_lnwpay.sync_order_status_timeout_seconds < this.callbackStates?.pay_t)
                && (
                    !this.orderData ||
                    this.orderData.order_status === 'wait_payment'
                );
        },
        canSubscribeRN(){
            return !this.canEditBuyerInfo;
        },
        currentURL(){
            return `${window.location.origin}${this.$route.fullPath}`;
        }
    },
    methods: {
        ...mapActions({
            initOrderId: 'order/initOrderId',
            initUserData: 'user/initUserData',
            fetchOrder: 'order/fetchOrder',
            actionCancelOrder: 'order/cancelOrder',
            setupConversionTrackers: 'order/setupConversionTrackers',
            actionTracker_beginCheckout: 'order/tracker_beginCheckout',
            waitUntilOrderStatusChange: 'order/waitUntilOrderStatusChange',
            actionUpdateOrderLangAndCurrency: 'order/updateOrderLangAndCurrency',
            actionSetMessengerOptInUserRef: 'order/setMessengerOptInUserRef',
            expandStep: 'order/expandStep',
            actionFacebookCheckboxPluginConfirmOptIn: 'order/facebookCheckboxPluginConfirmOptIn',
            actionOrderBuyAgain: 'order/orderBuyAgain',
            actionClaimOrder: 'order/claimOrder',
        }),
        ...mapMutations({
            storeActionSetForm: 'order/setForm',
            mutationSetFacebookCheckboxPluginCheckboxState: 'order/setFacebookCheckboxPluginCheckboxState',
            mutationSetLnwpayCallbackStates: `order/${SET_LNWPAY_CALLBACK_STATES}`,
        }),
        goToHeader(){
            this.scrollTo('#infoFixedTop');
        },
        gotoFirstStep(){
            this.scrollTo('#step1');
        },
        goToShippingType(step = 1){
            if(step == 1){
                this.$refs.shippingInfo.forceShippingStep1 = true;
            }else if(step == 2){
                this.$refs.shippingInfo.forceShippingStep2 = true;
            }
        },
        goToOrderInfo(){
            this.scrollTo('#orderInfo');
        },
        expandPaymentMethod(){
            this.expandStep({stepName: 'paymentMethod', close_another: true});
            this.scrollTo('#step3');
        },
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        selectAllText(event){
            event.target.select();
        },
        handleScroll(){
            let orderInfo = document.getElementById('orderInfo');
            let orderInfoHeight;
            if(orderInfo){
                orderInfoHeight = orderInfo.offsetHeight;
                if(this.expandOrderDetail){
                    this.orderFixedTop = window.scrollY > orderInfo.offsetTop + orderInfoHeight - 70;
                }
            }
            /*let elem = this.$refs.beginStepInfo;
            if (elem && window.scrollY > elem.offsetTop) {
                let that = this;
                setTimeout(function () {
                    that.fadeBanner = false;
                }, 250);
            }*/
            if(window.scrollY !== 0){
                this.fadeInfo = false;
            }
        },
        // eventTime(event_key){
        //     return this.orderData[this.orderStatusMetaDatas[event_key].time_key];
        // },
        onDoExpandOrderDetail(){
            this.expandOrderDetail = true;
            if(this.orderData.order_status === 'wait_payment'){
                this.goToOrderInfo();
            }else{
                this.goToHeader();
            }
        },
        async clickConfirmCancelOrder(){
            await this.actionCancelOrder();
            this.cancelSheet = false;
        },
        parseDateToCounter(fromdate){
            const arr = fromdate.split(/[- :]/);
            return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        },
        displayOrderID(id){
            if(id < 100){
                return '00' + id;
            }else if(id < 1000){
                return '0' + id;
            }
            return id;
        },
        loadFacebookJsSdk(){
            let language = 'en_US';
            if(this.$i18n.locale == 'th'){
                language = 'th_TH';
            }
            loadScript('https://connect.facebook.net/' + language + '/sdk.js', {})
                .then(() => {
                    // console.log('helo');
                    // window.FB.Event.subscribe('xfbml.render', ()=> { console.log("finished rendering plugins") });
                    window.FB.init({
                        appId: this.shopData?.fb_checkbox_plugin.app_id,
                        cookie: true,  // enable cookies to allow the server to access
                                       // the session
                        xfbml: true,  // parse social plugins on this page
                        version: this.shopData?.fb_checkbox_plugin.app_version ? this.shopData?.fb_checkbox_plugin.app_version : 'v15.0' // Specify the Graph API version to use
                    });
                    window.FB.Event.subscribe('messenger_checkbox', async (e) => {
                        // console.log(JSON.stringify(e));
                        // {"event":"rendered","is_after_optin":false,"user_ref":"21.1670419554370","ref":null}
                        // {"event":"checkbox","state":"unchecked","user_ref":"21.1670419554370","ref":null}
                        // {"event":"rendered","is_after_optin":false,"user_ref":"21.1670419554370","ref":null}
                        // {"event":"checkbox","state":"unchecked","user_ref":"21.1670419554370","ref":null}

                        if(e.event === 'rendered'){
                            // console.log("Plugin was rendered");
                        }else if(e.event === 'checkbox'){
                            this.mutationSetFacebookCheckboxPluginCheckboxState(e.state);
                            if(e.state === 'checked'){
                                // จะพยามยิงเลย เพราะจะมีบางเคสไม่ยอมยิง
                                // console.log('checked');
                                await store.dispatch('order/setIsAjaxLoading', true);
                                let res = await this.actionFacebookCheckboxPluginConfirmOptIn();
                                if(res){
                                    await store.dispatch('order/setIsAjaxLoading', false);
                                }
                            }
                        }else if(e.event === 'not_you'){
                            // console.log("User clicked 'not you'");
                        }else if(e.event === 'hidden'){
                            // console.log("Plugin was hidden");
                        }
                    });
                });
        },
        async ticking(xhr = false){
            if(xhr){
                try {
                    await this.fetchOrder();
                } catch(e) {
                    //
                }
            }

            if(
                this.orderData?.order_status
                && this.orderData?.order_status !== 'wait_payment'
            ){
                // หน่วง 5 วิแล้วกัน เผื่อ lnwpay sync order status เร็วจัด
                setTimeout(() => {
                    this.shouldShowLnwPayDialog = false;
                }, 5000);
                return;
            }

            let delay_time;
            if(this.timestamp - 30 < this.callbackStates?.pay_t){
                delay_time = 5; // ให้ load ทุก 5 วิ ในช่วงครึ่งนาทีแรก
            }else if(this.timestamp - 60 < this.callbackStates?.pay_t){
                delay_time = 10; // ให้ load ทุก 10 วิ ในช่วงนาทีแรก
            }else{
                delay_time = 30;
            }
            this.timestamp += delay_time;
            if(this.timestamp - config_lnwpay.sync_order_status_timeout_seconds < this.callbackStates?.pay_t){
                setTimeout(() => {
                    this.ticking(true);
                }, delay_time * 1000);
            }else{
                this.shouldShowLnwPayDialog = false;
            }
        },
        orderBuyAgain(){
            this.actionOrderBuyAgain({order_id: this.orderData.order_id});
        }
    },
    created(){
        window.addEventListener('scroll', this.handleScroll);
        if (this.$route.name === 'cart') {
            this.showCart = true;
        }
    },
    mounted(){


        let haveToReplace = false;
        let query = Object.assign({}, this.$route.query);

        // remove _lnwaccc from URL address
        if(query._lnwaccc){
            delete query._lnwaccc;
            haveToReplace = true;
        }

        // remove error parameter from LnwPay(2c2p)
        let hasLnwpayStates = false;
        const lnwpayCallbackStates = {};
        for(const key of ['pay_t', 'pay_s', 'pay_em', 'pay_ec']) {
            if(typeof query[key] !== "undefined"){
                lnwpayCallbackStates[key] = query[key];
                delete query[key];
                hasLnwpayStates = true;
            }
        }
        if(hasLnwpayStates){
            this.mutationSetLnwpayCallbackStates(lnwpayCallbackStates);
            this.timestamp = Math.floor((new Date()).getTime() / 1000);
            this.ticking();
            haveToReplace = true;
        }


        this.initUserData()
            .then(() => {
                this.initOrderId({
                    web_name: this.$route.params.web_name,
                    order_id: this.$route.params.order_id,
                    secret: this.$route.query.s,
                    type: this.$route.params.type ? this.$route.params.type : this.type,
                    is_x: this.$route.query.x,
                })
                    .then(() => {
                        this.$store.dispatch('order/setEnableSetIsAjaxResponse', true);

                        // ได้ MEASUREMENT_ID กับ pixel id มาจาก backend แล้ว
                        // สั่งเริ่มโหลด script tracker ต่างๆ
                        if(!this.existingOrderOwner){
                            // setup tracker และ fire beginCheckout
                            this.setupConversionTrackers()
                                .then(() => {
                                    // ถ้าสถานะเป็น wait_order ให้ยิง conversion begin checkout
                                    if(!this.showCart && this.orderData?.order_status === "wait_order"){
                                        this.actionTracker_beginCheckout();
                                    }
                                });
                        }
                        // Facebook checkbox plugin
                        if(this.shopData?.fb_checkbox_plugin && this.shopData?.fb_checkbox_plugin.app_id){
                            this.loadFacebookJsSdk();
                            // console.log('***** loadSDK');
                        }

                        if(query.claim){
                            delete query.claim;
                            haveToReplace = true;

                            this.actionClaimOrder();
                        }

                        this.handleShopData();
                        this.changeThemeColor();

                    });
            });

        if(haveToReplace){
            this.$router.replace({query});
        }
        // const item_exists_in_query_string = window.location.search.includes('item=');
        // if(item_exists_in_query_string){
        //     this.showCart = !this.showCart;
        // }


        if(this.isDesktop){
            this.expandOrderDetail = true;
        }
    },
    watch: {
        loader(){
            const l = this.loader;
            this[l] = !this[l];
            setTimeout(() => (this[l] = false), 3000);
            this.loader = null;
        },
        isPassAllStep(newValue){
            if(newValue && this.orderData?.order_status === 'wait_order'){
                this.expandPaymentMethod();
            }
        },

        // ต้องเอามาใส่ตรงนี้เพราะ component KplusWaitingPayment มันผลุบๆ โผล่ๆ ทำให้ method มันทำงานไม่เสร็จสมบูรณ์
        kplusDialogFlag(newValue, oldValue){
            if(!oldValue && newValue){
                // this.$refs.kplus_iframe.src = this.$store.state.order.kplusPaymentUrl;
                this.kplus_interval_id = setInterval(() => {
                    this.waitUntilOrderStatusChange();
                }, 5000);
            }else if(oldValue && !newValue){
                // this.$refs.kplus_iframe.src = 'about:blank';
                if(this.kplus_interval_id){
                    clearInterval(this.kplus_interval_id);
                }
            }
        },
        orderLanguage(newValue){
            if(newValue != 'th'){
                setLocale('en');
            }else{
                setLocale('th')
            }
            setTimeout(function (){
                store.dispatch('order/setIsShowOverlay', false)
            }, 500)
        },
        // for load credit_card JS SDK into page.
        creditCardJsSDK(newValue, oldValue){
            if(!oldValue && newValue){
                loadScript(newValue);
            }
        },
        // lnwpay callback states เพิ่ง redirect กลับมา
        callbackStatesJustEntered(newValue){
            if(newValue){
                this.shouldShowLnwPayDialog = true;
            }
        },
    },
}
</script>