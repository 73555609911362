<template>
    <div v-if="showThisFaqs" id="faq" class="faq mt-16 rounded-xl">
        <div v-if="orderData.greeting">
            <div class="just_flex clickable faqHeader" @click="showGreeting = !showGreeting">
                <h3 class="text-h6 mb-0">{{ $t('ข้อความจากร้าน') }}</h3>
                <v-icon :class="showGreeting?'flip':''" large>mdi-chevron-down</v-icon>
            </div>
            <v-scroll-y-transition hide-on-leave>
                <div v-if="showGreeting" class="faqContent">
                    <v-divider></v-divider>
                    <div class="pt-8 pb-4" v-html="orderData.greeting"></div>
                </div>
            </v-scroll-y-transition>
        </div>
        <div v-if="cpFaqs?.length">
            <div class="just_flex clickable faqHeader" @click="showFaq = !showFaq">
                <h3 class="text-h6 mb-0">{{ $t('คำถามที่พบบ่อย') }}</h3>
                <v-icon :class="showFaq?'flip':''" large>mdi-chevron-down</v-icon>
            </div>
            <v-scroll-y-transition hide-on-leave>
                <div v-if="showFaq" class="faqContent">
                    <v-divider></v-divider>
                    <div v-for="(faq, index) in cpFaqs"
                         class="faqBox"
                         :key="'faq'+ index">
                        <div class="question clickable"
                             @click="toggleFaq(index)">
                            <div v-html="faq['question']"></div>
                            <v-icon class="mr-1" :class="activeFaqs.includes(index)?'flip':''">mdi-chevron-down</v-icon>
                        </div>
                        <v-scroll-y-transition hide-on-leave>
                            <div v-if="activeFaqs.includes(index)" class="answer" v-html="faq['answer']"></div>
                        </v-scroll-y-transition>
                    </div>
                </div>
            </v-scroll-y-transition>
        </div>
        <div v-if="orderData.how2refund">
            <div class="just_flex clickable faqHeader" @click="showRefund = !showRefund">
                <h3 class="text-h6 mb-0">{{ $t('นโยบายการเปลี่ยนหรือคืนสินค้า') }}</h3>
                <v-icon :class="showRefund?'flip':''" large>mdi-chevron-down</v-icon>
            </div>
            <v-scroll-y-transition hide-on-leave>
                <div v-if="showRefund" class="faqContent">
                    <v-divider></v-divider>
                    <div class="pt-8 pb-4" v-html="orderData.how2refund"></div>
                </div>
            </v-scroll-y-transition>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
    name: "faqs",
    data() {
        return {
            activeFaqs: [],
            showFaq: false,
            showRefund: false,
            showGreeting: false,
        }
    },
    methods: {
        toggleFaq(index) {
            if (this.activeFaqs.includes(index)) {
                // If the FAQ is already open, close it
                this.activeFaqs = this.activeFaqs.filter(i => i !== index);
            } else {
                // Otherwise, open the FAQ
                this.activeFaqs.push(index);
            }
        },
    },
    computed: {
        ...mapState({
            orderData: state => state.order.orderData,
        }),
        showThisFaqs() {
            return this.orderData.greeting || this.orderData.how2refund || this.cpFaqs?.length;
        },
        cpFaqs(){
            let faqs = [];
            if(this.orderData.faqs == null || (this.orderData.faqs === '')) {
                faqs = null;
            } else {
                let lines = this.orderData.faqs.split("\n");
                let faq_length = Math.floor(lines.length / 2);

                for (let i = 0; i < faq_length; i++) {
                    let faq = {
                        question: lines[i * 2],
                        answer: lines[i * 2 + 1]
                    };
                    faqs.push(faq);
                }
            }

            return faqs;
        }
    }
}
</script>
<style scoped></style>