<template>
    <div>
        <v-combobox :label="$t('ประเทศ')"
                    outlined
                    :rules="rulesCommon"
                    :items=countryList
                    v-model="country"
                    hide-details
                    class="mb-4"></v-combobox>
        <v-text-field :label="$t('รัฐ / จังหวัด')"
                      outlined
                      :rules="rulesCommon"
                      v-model="custom_province"
                      validate-on-blur
                      v-disabled-icon-focus
                      clearable
                      hide-details
                      class="mb-4"></v-text-field>
        <v-text-field style="max-width: 160px"
                      :label="$t('รหัสไปรษณีย์')"
                      :rules="rulesCommon"
                      validate-on-blur
                      pattern="\d*"
                      clearable
                      v-disabled-icon-focus
                      outlined
                      v-model="postalCode"></v-text-field>
    </div>
</template>
<script>
import country_db from "../../../resources/country-code.json";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";

export default {
    name: "InternationalAddress",
    mixins: [MixinsDisabledIconFocus],
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            country: this.value?.country ? this.value?.country : null,
            postalCode: this.value?.postalCode ? this.value?.postalCode : null,
            custom_province: this.value?.custom_province ? this.value?.custom_province : null,
        };
    },
    computed: {
        rulesCommon() {
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
            ];
        },
        countryList() {
            return Object.values(country_db).sort();
        },
        country_code() {
            let code = null;
            let that = this;
            Object.keys(country_db)
                .forEach(function eachKey(key) {
                    if(that.country == country_db[key]){
                        code = key;
                    }
                });
            return code;
        },
        getPayload() {
            const payload = {};
            payload.country_code = this.country_code;
            payload.country = this.country;
            payload.zipcode = this.postalCode;
            payload.province = this.custom_province;

            return payload;
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', {
                country: this.country,
                country_code: this.country_code,
                postalCode: this.postalCode,
                custom_province: this.custom_province,
            });
        },
    },
    watch: {
        postalCode() {
            this.emitInput();
        },
        custom_province() {
            this.emitInput();
        },
        country() {
            this.emitInput();
        },
        country_code() {
            this.emitInput();
        },
    },
    mounted() {

    },
}
</script>
<style scoped></style>