<template>
    <div id="login-wrapper">
        <div class="step">
            <div class="needDiv">

                <div class="lnwme-container py-4">
                    <v-overlay :value="loading"
                               opacity="1"
                               dark
                               color="white"
                               z-index="999">
                        <div class="center">
                            <v-row class="fill-height px-4 py-3"
                                   align-content="center"
                                   justify="center">
                                <v-col class="text-subtitle-1 text-center text-body"
                                       cols="12">
                                    {{ $t('กรุณารอสักครู่ กำลังดำเนินการ') }}...
                                </v-col>
                                <v-col cols="12">
                                    <v-progress-linear color="primary accent-4"
                                                       indeterminate
                                                       rounded
                                                       height="6"></v-progress-linear>
                                </v-col>
                            </v-row>
                        </div>
                    </v-overlay>
                    <div v-if="!loading" class="stepContent text-center">
                        <div class="my-12"><img :src="require('@/assets/construction.webp')"
                                   alt=""></div>
                        <div class="text-body-1 p-1">ขออภัยค่ะ URL ที่คุณเรียกนั้นไม่ถูกต้องหรือไม่มีอยู่แล้ว</div>
                        <div class="text-subtitle-2 text-secondary">Sorry, this URL is invalid or have been hidden or deleted.</div>
                        <div class="flex_center" style="gap: 20px;">
                            <v-btn onclick="history.back()"
                                   large
                                   :x-large="isDesktop"
                                   style="min-width: 100px"
                                   color="primary"
                                   class="mt-6">
                                <v-icon color="white"
                                        class="mr-2">mdi-chevron-left
                                </v-icon> {{ $t('ย้อนกลับ')}}
                            </v-btn>
                            <v-btn onclick="window.location.reload();"
                                   large
                                   :x-large="isDesktop"
                                   style="min-width: 200px; text-transform: unset;"
                                   class="mt-6">
                                <v-icon color="black"
                                        class="mr-2">mdi-reload
                                </v-icon>
                                <template v-if="!alreadyDisplayCountDown">{{ $t('โหลดอัตโนมัติในอีก') }} {{countdown}}s</template>
                                <template v-else>{{ $t('ลองโหลดอีกครั้ง') }} </template>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <footer-component></footer-component>
        </div>
    </div>
</template>
<script>
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import SiteUrl from "@/libraries/SiteUrl.js";
import FooterComponent from "@/components/Order/components/FooterComponent";


export default {
    name: "PageNotFound",
    components: {
        FooterComponent,
    },
    data() {
        return {
            loading: true,
            alreadyDisplayCountDown : sessionStorage.getItem('reload_page_not_found'),
            countdown: 9,
            counting: false,
            intervalId: null
        };
    },
    computed: {

    },
    mixins: [MixinsHelper],
    methods: {
        accountUrl(uri) {
            return SiteUrl.account(uri);
        },
        startCountdown() {
            if (!this.counting) {
                this.counting = true;
                this.intervalId = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown--;
                    } else {
                        clearInterval(this.intervalId);
                        this.counting = false;
                        window.location.reload();
                    }
                }, 1000);
            }
        }
    },
    created(){
        if(this.alreadyDisplayCountDown){
            sessionStorage.removeItem('reload_page_not_found');
        }else{
            this.startCountdown();
            sessionStorage.setItem('reload_page_not_found', true);
        }
    },
    mounted(){
        setTimeout(()=>{
            this.loading = false;
        },2000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    }
}
</script>
<style scoped></style>