<template>
    <component :is="isDesktop?'VDialog':'VBottomSheet'"
               v-model="kplus_dialog"
               persistent
               :max-width="isDesktop?'600px':'100%'">
        <v-card>
            <div :class="isDesktop?'modal-body pb-0':''">
                <v-list-item>
                    <v-list-item-content>
                        <div class="text-overline mb-4 small pink--text">
                            <countdown :end-time="new Date().getTime() + 900000">
                            <span slot="process"
                                  slot-scope="anyYouWantedScopName">
                                {{ $t('กรุณาชำระเงินภายใน') }} {{ anyYouWantedScopName.timeObj.m }}:{{ anyYouWantedScopName.timeObj.s }}
                            </span> <span slot="finish">{{ $t('หมดอายุแล้ว') }}</span>
                            </countdown>
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                            <div class="text pb-1">{{ $t('ชำระเงินผ่านแอพ KPLUS') }}</div>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ $t('โดยอีกสักครู่ จะมีข้อความแจ้งเตือนการชำระเงินปรากฏบนหน้าจอโทรศัพท์มือถือของท่าน แตะข้อความนั้น และดำเนินการชำระเงินถัดไป') }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar tile
                                        size="50">
                        <img :src="require('@/assets/payment/mobile-bank/kplus.png')"/>
                    </v-list-item-avatar>
                </v-list-item>
                <v-list-item>
                    <div class="row">
                        <div class="col-3"><img class="border rounded"
                                                :src="require('@/assets/payment/mobile-bank/kplus/kplus_step1.jpg')"/>
                        </div>
                        <div class="col-3"><img class="border rounded"
                                                :src="require('@/assets/payment/mobile-bank/kplus/kplus_step2.jpg')"/>
                        </div>
                        <div class="col-3"><img class="border rounded"
                                                :src="require('@/assets/payment/mobile-bank/kplus/kplus_step3.jpg')"/>
                        </div>
                        <div class="col-3"><img class="border rounded"
                                                :src="require('@/assets/payment/mobile-bank/kplus/kplus_step4.jpg')"/>
                        </div>
                    </div>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            {{ $t('กรุณาติดตั้ง K PLUS Application ก่อนทำการชำระเงิน โดยดาวน์โหลดแอพได้ที่') }}
                        </v-list-item-subtitle>
                        <div class="row justify-content-md-center" style="max-width: 360px;">
                            <a class="col-6"
                               href="https://itunes.apple.com/th/app/k-plus/id361170631?platform=iphone&amp;preserveScrollPosition=true#platform/iphone"
                               target="_blank"> <img class="scale-with-grid"
                                                     :src="require('@/assets/payment/mobile-bank/kplus/logo-appstore.jpg')"
                                                     alt="ดาวโหลด app kplus ที่ app store"
                                                     title="ดาวโหลด app kplus ที่ app store"/> </a> <a class="col-6"
                                                                                                       href="https://play.google.com/store/apps/details?id=com.kasikorn.retail.mbanking.wap&amp;hl=th"
                                                                                                       target="_blank">
                            <img class="scale-with-grid"
                                 :src="require('@/assets/payment/mobile-bank/kplus/logo-googleplay.jpg')"
                                 alt="ดาวโหลด app kplus ที่ google play"
                                 title="ดาวโหลด app kplus ที่ google play"/> </a>
                        </div>
                        <v-list-item-subtitle class="center">
                            <v-btn color="error"
                                   text
                                   @click="closeDialog">
                                {{ $t('ยกเลิกและปิดหน้าต่างนี้') }}
                            </v-btn>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-card>
    </component>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import {mapActions} from "vuex";
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "KplusWaitingPayment",
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet
    },
    mixins: [
        MixinsHelper,
    ],
    computed: {
        kplus_dialog() {
            return this.$store.state.order.kplusDialogFlag;
        },
    },
    methods: {
        ...mapActions({
            waitUntilOrderStatusChange: 'order/waitUntilOrderStatusChange',
            kplusDeactivateDialog: 'order/kplusDeactivateDialog',
        }),
        closeDialog() {
            this.kplusDeactivateDialog();
        },
    },
}
</script>
<style scoped></style>